<template>
	<section>
		<top-bar title="All Entities" :action="{ name: 'Create Entity', to: { name: 'create-entity' } }" />
		<div v-if="!isLoading">
			<empty v-if="!sorted.length" />
			<list v-else :entities="sorted" />
		</div>
	</section>
</template>

<script>
import List from './List'
import { mapState, mapGetters } from 'vuex'
import { GET_ALL } from '@/store/actions.type'
import topBar from '@/components/shared/topBar'
import Empty from './Empty'

export default {
	name: 'ManageEntity',
	components: {
		List,
		Empty,
		topBar
	},
	computed: {
		...mapGetters('entities', ['sorted']),
		...mapState('ui', ['isLoading', 'refCount'])
	},
	created() {
		this.fetch()
	},
	methods: {
		fetch() {
			this.$store.dispatch(`entities/${GET_ALL}`).then(() => {})
		}
	}
}
</script>
