<template>
	<div class="mt-6 grid grid-cols-1 row-gap-8">
		<h3 class="text-xl leading-8 font-semibold font-display text-gray-900 sm:text-3xl sm:leading-9">
			Creators
		</h3>
		<div v-if="model.creators">
			<entity-described
				v-for="creator in model.creators"
				:key="creator.id"
				:item-id="creator.id"
				:model="model"
				:item="getItem(creator.id, 'creators')"
				item-type="creators"
				item-edit="edit-creator"
				icon="pencil-ruler"
				:connections="connections"
				source-action="creates"
			/>
		</div>
		<p v-else>No Creators added to the Model</p>
		<hr />
		<h3 class="text-xl leading-8 font-semibold font-display text-gray-900 sm:text-3xl sm:leading-9">
			Entities
		</h3>
		<div v-if="model.entities">
			<entity-described
				v-for="entity in model.entities"
				:key="entity.id"
				:item-id="entity.id"
				:item="getItem(entity.id, 'entities')"
				:model="model"
				:connections="connections"
				source-action="is used by"
				item-edit="edit-entity"
				icon="cube"
				target-action="is comprised of"
				item-type="entities"
			/>
		</div>
		<p v-else>No Entities added to the Model</p>
		<hr />
		<h3 class="text-xl leading-8 font-semibold font-display text-gray-900 sm:text-3xl sm:leading-9">
			Fields
		</h3>
		<div v-if="model.fields">
			<entity-described
				v-for="field in model.fields"
				:key="field.id"
				:item-id="field.id"
				:model="model"
				:item="getItem(field.id, 'fields')"
				icon="edit"
				:connections="connections"
				item-edit="edit-field"
				source-action="is used by"
				target-action="is created by"
				item-type="fields"
			/>
		</div>
		<p v-else>No Fields added to the Model</p>
		<hr />
		<h3 class="text-xl leading-8 font-semibold font-display text-gray-900 sm:text-3xl sm:leading-9">
			Channels
		</h3>
		<div v-if="model.channels">
			<entity-described
				v-for="channel in model.channels"
				:key="channel.id"
				:item="getItem(channel.id, 'channels')"
				:item-id="channel.id"
				:model="model"
				icon="dot-circle"
				item-edit="edit-channel"
				:connections="connections"
				target-action="is the destination for"
				item-type="channels"
			/>
		</div>
		<p v-else>No Channels added to the Model</p>
	</div>
</template>

<script>
import EntityDescribed from '@/components/entity/Described'
export default {
	name: 'ModelDescription',
	components: {
		EntityDescribed
	},
	props: {
		model: {
			type: Object,
			default: function() {
				return {}
			}
		},
		connections: {
			type: Array,
			default: function() {
				return []
			}
		}
	},
	methods: {
		getItem(id, type) {
			return this.$store.state[type] ? this.$store.state[type].all.find((d) => d.id === id) : {}
		}
	}
}
</script>

<style></style>
