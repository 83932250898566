const insight = { RECOMMENDATION: 'recommendation', RISK: 'risk', ISSUE: 'issue' }

export const recommendationMixin = () => ({
	data() {
		return {
			recommendations_internal: [],
			connectioninformation_internal: []
		}
	},
	computed: {
		insight() {
			return this.recommendations_internal.filter((r) => r)
		},
		insightRisk() {
			return this.insight.filter((r) => r.class === insight.RISK)
		},
		insightRecommendation() {
			return this.insight.filter((r) => r.class === insight.RECOMMENDATION)
		},
		insightIssue() {
			return this.insight.filter((r) => r.class === insight.ISSUE)
		}
	},
	async mounted() {
		this.connectioninformation_internal = await this.connectionInformation()

		this.sharedRecommendations()
		this.recommendations_internal = [...this.recommendations_internal, ...this[`${this.itemType}Recommendations`]()]
	},
	methods: {
		sharedRecommendations() {
			this.recommendations_internal.push(this.emptyDescription())
		},
		fieldsRecommendations() {
			let recommendations = []

			recommendations.push(this.gdprInformation())
			recommendations.push(...this.fieldHasCreatorsAndEntities())

			return recommendations
		},
		entitiesRecommendations() {
			let recommendations = []
			recommendations.push(...this.hasGdprSource())
			recommendations.push(...this.entityHasFieldsAndChannels())
			return recommendations
		},
		creatorsRecommendations() {
			let recommendations = []
			recommendations.push(...this.hasGdprTarget())
			recommendations.push(this.creatorHasFields())
			return recommendations
		},
		channelsRecommendations() {
			let recommendations = []
			recommendations.push(this.channelHasEntities())
			return recommendations
		},
		emptyDescription() {
			if (!this.item.description) {
				return {
					id: 'Missing Description',
					class: insight.RECOMMENDATION,
					information: `Descriptions should be added to all ${this.itemType}`,
					link: { name: this.itemEdit, params: { id: this.item.id } }
				}
			}
		},
		gdprInformation() {
			if (this.item.gdprStatus && this.item.gdprStatus !== 'no') {
				if (!this.item.gdprPurpose) {
					return {
						id: 'Missing GDPR Purpose',
						class: insight.RISK,
						information: `GDPR Purpose should be added to all GDPR items`
					}
				}
				// TODO: Could look up field to see if this is a regular GDPR Field
				return
			} else {
				// TODO: Validate if purpose makes sense
			}
		},

		hasGdprSource() {
			let gdprFields = []
			let fields = this.connectioninformation_internal.targetConnections
			for (const field of fields) {
				let found = this.$store.state.fields.all.find((f) => f.id === field.source && f.gdprStatus !== 'no') //&& f.gdprStatus !== 'no')
				if (found) {
					gdprFields.push({
						id: 'GDPR Source',
						name: found.name,
						class: insight.RISK,
						gdprStatus: found.gdprStatus,
						information: `Consuming '${found.name}' which is flagged as '${found.gdprStatus}', you will need to ensure destination is GDPR compliant`
					})
				}
			}
			return gdprFields
		},
		hasGdprTarget() {
			let gdprFields = []
			let fields = this.connectioninformation_internal.sourceConnections
			for (const field of fields) {
				let found = this.$store.state.fields.all.find((f) => f.id === field.target && f.gdprStatus !== 'no') //&& f.gdprStatus !== 'no')
				if (found) {
					gdprFields.push({
						id: 'GDPR Source',
						name: found.name,
						class: insight.RISK,
						gdprStatus: found.gdprStatus,
						information: `Creating '${found.name}' which is flagged as '${found.gdprStatus}', you will need to ensure GDPR compliance`
					})
				}
			}
			return gdprFields
		},

		connectionInformation() {
			let source = this.$store.getters['connections/source'](this.itemId)
			let target = this.$store.getters['connections/target'](this.itemId)
			let sourceConnections = source.filter((s) => s.modelId === this.model.id)
			let targetConnections = target.filter((s) => s.modelId === this.model.id)

			let isSource = sourceConnections.length > 0
			let isTarget = targetConnections.length > 0
			let hasTargetAndSource = isSource && isTarget

			return {
				name: this.item._name,
				sourceConnections,
				targetConnections,
				isSource,
				isTarget,
				hasTargetAndSource
			}
		},

		creatorHasFields() {
			if (!this.connectioninformation_internal.isSource) {
				return {
					id: 'Missing Connection',
					class: insight.ISSUE,
					information: 'A Creator should be creating Fields in your Model',
					link: 'creators'
				}
			}
		},
		fieldHasCreatorsAndEntities() {
			let result = []
			if (!this.connectioninformation_internal.isSource) {
				result.push({
					id: 'Missing Connection',
					class: insight.ISSUE,
					information: 'A Field should be used by Entities in your Model',
					link: 'entities'
				})
			}
			if (!this.connectioninformation_internal.isTarget) {
				result.push({
					id: 'Missing Connection',
					class: insight.ISSUE,
					information: 'A Field should have a Creator in your Model',
					link: 'creators'
				})
			}

			return result
		},
		entityHasFieldsAndChannels() {
			let result = []
			if (!this.connectioninformation_internal.isSource) {
				result.push({
					id: 'Missing Connection',
					class: insight.ISSUE,
					information: 'An Entity should be used by Channels in your Model',
					link: 'channels'
				})
			}
			if (!this.connectioninformation_internal.isTarget) {
				result.push({
					id: 'Missing Connection',
					class: insight.ISSUE,
					information: 'An Entity should have Fields in your Model',
					link: 'entities'
				})
			}

			return result
		},
		channelHasEntities() {
			if (!this.connectioninformation_internal.isTarget) {
				return {
					id: 'Missing Connection',
					class: insight.ISSUE,
					information: 'A Channel should use Entities in your Model',
					link: 'channels'
				}
			}
		}
	}
})
