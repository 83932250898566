<template>
	<form @submit.prevent>
		<confirmation
			:title="confirm.title"
			:message="confirm.message"
			:ok-button="confirm.okButton"
			:open="confirm.open"
			@ok="deleteConfirmed"
			@cancel="deleteCancelled"
		/>
		<div class="md:grid md:grid-cols-3 md:gap-6">
			<div class="md:col-span-1">
				<h3 class="text-lg font-medium leading-6 text-gray-900">Entity</h3>
				<div class="mt-1 text-sm leading-5 text-gray-500">
					<p class="mt-3">
						An Entity is an asset which is made up of Fields and is used by Channels.
					</p>
				</div>
				<button v-if="loaded" class="text-xs text-red-200" @click="confirm.open = true">Delete</button>
			</div>
			<div class="mt-5 md:mt-0 md:col-span-2">
				<div class="grid grid-cols-3 gap-6">
					<div class="col-span-4">
						<label for="entityName" class="block text-sm font-medium leading-5 text-gray-700">
							Entity Name
						</label>
						<div class="mt-1 rounded-md shadow-sm">
							<input
								id="entityName"
								v-model.lazy="item.name"
								type="entityName"
								class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
							/>
						</div>
						<p v-if="!$v.item.name.required" class="text-red-500 text-xs italic">Name is required</p>
					</div>
					<div class="col-span-4">
						<label for="description" class="block text-sm font-medium leading-5 text-gray-700">
							Description
						</label>
						<div class="mt-1 rounded-md shadow-sm">
							<textarea
								id="description"
								v-model.lazy="item.description"
								rows="3"
								class="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
							></textarea>
						</div>
						<p class="mt-2 text-sm text-gray-500">Entity Description.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-8 border-t border-gray-200 pt-5">
			<div class="flex justify-end">
				<span v-if="errors" class="mr-3 inline-flex">
					<p class="text-red-500 text-xs italic">
						The form above has errors,
						<br />
						please check errors and resubmit
					</p>
				</span>
				<span class="inline-flex rounded-md shadow-sm">
					<router-link
						:to="{ name: 'entities' }"
						tag="button"
						type="cancel"
						class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
					>
						Cancel
					</router-link>
				</span>
				<span class="ml-3 inline-flex rounded-md shadow-sm">
					<button type="submit" class="btn btn-indigo" @click="save">
						Save
					</button>
				</span>
			</div>
		</div>
	</form>
</template>

<script>
import { createMixin } from '@/mixins/configMixins'
import { required } from 'vuelidate/lib/validators'
export default {
	name: 'CreateEntity',
	mixins: [createMixin('entities')],
	validations: {
		item: {
			name: {
				required
			},
			description: {}
		}
	}
}
</script>
