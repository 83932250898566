<template>
	<div>
		<model-header :model="model" :connections="connections" />
		<div v-if="modelData.series[0].data.length">
			<h3 class="text-xl leading-8 font-semibold font-display text-gray-900 sm:text-3xl sm:leading-9 pt-6">
				Overview
			</h3>
			<div :style="chartSize">
				<chart :options="modelData" :style="chartSize" autoresize @dblclick="handleClicked"></chart>
			</div>

			<model-description :model="model" :connections="connections" />
		</div>
		<div v-else class="bg-white">
			<div class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
				<h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
					This Model is empty
					<br />
					Configuring some assets will fix that.
				</h2>
				<div class="mt-8 flex justify-center">
					<div class="inline-flex rounded-md shadow">
						<router-link
							tag="button"
							:to="{ name: 'model-config', params: { id: `${model.id}`, name: `${model._name}` } }"
							class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
						>
							<svg class="-ml-1 mr-2 h-5 w-5 text-indigo-500" fill="currentColor" viewBox="0 0 20 20">
								<path
									d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
								/>
							</svg>
							Configure
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import ModelHeader from '@/components/model/header'
import helpers from '@/helpers/utils'
import ModelDescription from './Described'
export default {
	name: 'ModelOverview',
	components: {
		ModelDescription,
		ModelHeader
	},
	data() {
		return {
			chartData: [],
			chartLinks: [],
			active: false
		}
	},
	computed: {
		...mapState({
			model: (state) => state.models.current,
			connections: (state) => state.connections.all.filter((c) => c.modelId === state.models.current.id)
		}),
		...mapState({
			modelCount: (state) => state.models.all.length,
			fieldCount: (state) => state.fields.all.length,
			channelCount: (state) => state.channels.all.length,
			creatorCount: (state) => state.creators.all.length,
			entityCount: (state) => state.entities.all.length
		}),
		hasAll() {
			return this.model.fieldCount > 0 && this.model.channelCount > 0 && this.model.creatorCount > 0 && this.model.entityCount > 0
		},
		chartSize() {
			return {
				height: `${this.modelData.series[0].data.length * 20 + 100}px`,
				width: '100%'
			}
		},
		modelData() {
			return {
				series: [
					{
						type: 'sankey',
						focusNodeAdjacency: true,
						label: {
							formatter: '{c}'
						},
						lineStyle: {
							normal: {
								color: 'source',
								curveness: 0.5
							}
						},
						left: 50.0,
						top: 50.0,
						right: 150.0,
						bottom: 25.0,
						name: 'bob',
						data: this.getModelData(),
						links: this.getModelConnections(),
						levels: [
							{
								depth: 0,
								itemStyle: {
									color: '#4299E1' //'#099DB0'
								}
							},
							{
								depth: 1,
								itemStyle: {
									color: '#fd0084'
								}
							},
							{
								depth: 2,
								itemStyle: {
									color: '#38B2AC' //'#FDE219'
								}
							},
							{
								depth: 3,
								itemStyle: {
									color: '#ECC94B' //'#FEB10D'
								}
							}
						]
					}
				],
				title: {
					text: this.model.name + ' ' + 'Diagram',
					left: 'center',
					padding: 10,
					textStyle: {
						fontSize: 12,
						fontFamily: 'sans-serif'
					}
				},

				toolbox: {
					show: true,
					orient: 'vertical',
					top: 'center',
					feature: {
						dataView: { readOnly: false },
						restore: {},
						saveAsImage: {}
					}
				},
				color: ['#fd0084']
			}
		}
	},
	methods: {
		pageUp() {
			window.scroll({ top: 0, left: 0, behavior: 'auto' })
		},
		getModelData() {
			let components = [
				{ name: 'creators', depth: 0, conf: 'configure-creator' },
				{ name: 'fields', depth: 1, conf: 'configure-field' },
				{ name: 'entities', depth: 2, conf: 'configure-entity' },
				{ name: 'channels', depth: 3, conf: 'configure-channel' }
			]
			let x = []

			components.forEach((comp) => {
				if (!this.model[comp.name]) {
					return
				}

				let m = []
				if (comp.name === 'fields') {
					m = Object.values(this.model.fields).map((c) => ({
						name: c.id,
						_name: helpers.shortName(c.name),
						conf: comp.conf,
						value: c.name,
						type: comp.name,
						depth: comp.depth,
						itemStyle: {
							color: this.getGdprColor(c.id)
						}
					}))
				} else {
					m = Object.values(this.model[comp.name]).map((c) => ({
						name: c.id,
						_name: helpers.shortName(c.name),
						conf: comp.conf,
						value: c.name,
						type: comp.name,
						depth: comp.depth
					}))
				}

				x.push(...m)
			})
			return x
		},
		getGdprColor(id) {
			let x = this.$store.state.fields.all.find((f) => f.id == id)
			let status = x ? x.gdprStatus : 'not-found'
			switch (status) {
				case 'sensitive':
					return '#F56565'
				case 'personal':
					return '#ED8936'
				case 'not-found':
					return '#00'
				default:
					return '#48BB78'
			}
		},
		getModelConnections() {
			return Object.values(this.connections)
		},

		handleClicked(i) {
			if (i.dataType === 'node') {
				this.$router.push({ to: 'model', hash: `#${i.name}` })
				//this.$router.push({ name: i.data.conf, params: { configId: i.data.name, configName: i.data._name } })
			}
			//  else if (i.dataType === 'edge') {
			// }
		}
	}
}
</script>
