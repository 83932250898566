<template>
	<div class="lg:flex lg:items-center lg:justify-between py-5 border-b ">
		<div class="flex-1 min-w-0">
			<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
				{{ model.name }}
			</h2>
			<div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<!-- <font-awesome-icon class="flex-shrink-0 mr-2 text-gray-400" :icon="['fad', 'pencil-ruler']" /> -->
					{{ model.creatorCount ? model.creatorCount : 0 }} Creators
				</div>
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<!-- <font-awesome-icon class="flex-shrink-0 mr-2 text-gray-400" :icon="['fad', 'edit']" /> -->
					{{ model.fieldCount ? model.fieldCount : 0 }} Fields
				</div>
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<!-- <font-awesome-icon class="flex-shrink-0 mr-2 text-gray-400" :icon="['fad', 'cube']" /> -->
					{{ model.entityCount ? model.entityCount : 0 }} Entities
				</div>
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<!-- <font-awesome-icon class="flex-shrink-0 mr-2 text-gray-400" :icon="['fad', 'dot-circle']" /> -->
					{{ model.channelCount ? model.channelCount : 0 }} Channels
				</div>
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
					<!-- <font-awesome-icon class="flex-shrink-0 mr-2 text-gray-400" :icon="['fad', 'link']" /> -->
					{{ connections.length }} Connections
				</div>
			</div>
		</div>
		<div class="mt-5 flex lg:mt-0 lg:ml-4">
			<span class=" sm:block shadow-sm rounded-md">
				<router-link
					tag="button"
					:to="{ name: 'model-config', params: { id: `${model.id}`, name: `${model._name}` } }"
					class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
				>
					<svg class="-ml-1 mr-2 h-5 w-5 text-indigo-500" fill="currentColor" viewBox="0 0 20 20">
						<path
							d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
						/>
					</svg>
					Configure
				</router-link>
			</span>
			<router-link class="ml-2 btn btn-indigo" :to="{ name: 'edit-model' }">
				Edit
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModelHeader',
	props: {
		model: {
			type: Object,
			required: true,
			default: () => ({})
		},
		connections: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			open: false
		}
	}
}
</script>

<style></style>
