<template>
	<div class="bg-gray-100">
		<div class="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
			<div class="max-w-3xl mx-auto">
				<div class="bg-white shadow sm:rounded-lg">
					<div class="px-4 py-5 sm:p-6">
						<h3 class="text-lg leading-6 font-medium text-gray-900">
							There are no Entities at the moment, oh.
						</h3>
						<div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
							<p>
								An Entity represents a collection of Fields.
							</p>
						</div>
						<div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
							<p>
								For example a Contact (
								<em>Entity</em>
								) could be made up of First Name, Last Name, Position, Salutation (all
								<em>Fields</em>
								) and then they could be used in a Contact List System (
								<em>Channel</em>
								)
							</p>
						</div>
						<model-animation />
						<div class="mt-3 text-sm leading-5">
							<router-link
								:to="{ name: 'create-entity' }"
								class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
							>
								Create your first Entity →
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import modelAnimation from '@/components/shared/modelAnimation'
export default {
	name: 'EmptyEntities',
	components: {
		modelAnimation
	}
}
</script>
