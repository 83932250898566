<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'Entities',
	data() {
		return {
			actions: [{ name: 'Entity', route: 'create-entity', icon: 'plus-circle' }]
		}
	},
	metaInfo() {
		return {
			title: 'Entities'
		}
	}
}
</script>
