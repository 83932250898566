<template>
	<div v-if="item" class="bg-white overflow-hidden shadow rounded-lg m-5">
		<div class="border-b border-gray-200 px-4 py-2 sm:px-6">
			<!-- Content goes here -->
			<!-- We use less vertical padding on card headers on desktop than on body sections -->
			<h3 class="text-lg leading-7 font-medium tracking-tight text-gray-900">
				{{ item.name }}
			</h3>
			<p class="mt-1 text-sm leading-5 text-gray-500">
				{{ item.description }}
			</p>
		</div>
		<div v-if="target.length || source.length" class="border-b border-gray-200 px-4 py-5 sm:p-6">
			<div v-if="target.length">
				<entity-chart :map-title="targetAction" :chart-data="getChartDataTarget(target)" />
			</div>
			<div v-if="source.length">
				<entity-chart :map-title="sourceAction" :chart-data="getChartDataSource(source)" />
			</div>
		</div>
		<div v-if="insight.length" class="px-4 py-4 sm:px-6 ">
			<!-- Content goes here -->
			<!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
			<ul class="border border-gray-200 rounded-md">
				<li
					v-for="(r, i) in insightRecommendation"
					:key="i + 'recommendations'"
					class="pl-3 pr-4 py-2 flex items-center justify-between text-sm leading-5"
				>
					<div class="w-0 flex-1 flex items-center">
						<!-- <font-awesome-icon class="text-blue-500" :icon="['fad', 'info-circle']" /> -->
						<span class="ml-2 truncate">
							{{ r.information }}
						</span>
					</div>
					<div class="ml-4 flex-shrink-0">
						<router-link class="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out" :to="r.link">
							Resolve
						</router-link>
					</div>
				</li>
				<li v-for="(r, i) in insightRisk" :key="i + 'risks'" class="pl-3 pr-4 py-2 flex items-center justify-between text-sm leading-5">
					<div class="w-0 flex-1 flex items-center">
						<!-- <font-awesome-icon class="text-orange-400" :icon="['fad', 'exclamation-triangle']" /> -->
						<span class="ml-2 truncate">
							{{ r.information }}
						</span>
					</div>
				</li>
				<li v-for="(r, i) in insightIssue" :key="i + 'issues'" class="pl-3 pr-4 py-2 flex items-center justify-between text-sm leading-5">
					<div class="w-0 flex-1 flex items-center">
						<!-- <font-awesome-icon class="text-red-500" :icon="['fad', 'exclamation-square']" /> -->
						<span class="ml-2 truncate">
							{{ r.information }}
						</span>
					</div>
					<div class="ml-4 flex-shrink-0">
						<router-link
							class="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
							:to="{ name: 'model-config', params: { id: `${model.id}`, name: `${model._name}` }, hash: `#${r.link}` }"
						>
							Resolve
						</router-link>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import EntityChart from '@/components/charts/entityMap'
import { recommendationMixin } from '@/mixins/recommendation'
export default {
	name: 'EntityDescribed',
	components: {
		EntityChart
	},
	mixins: [recommendationMixin()],
	props: {
		itemId: {
			type: String,
			default: ''
		},
		model: {
			type: Object,
			default: function() {
				return {}
			}
		},
		item: {
			type: Object,
			default: function() {
				return {}
			}
		},
		connections: {
			type: Array,
			default: function() {
				return []
			}
		},
		itemType: {
			type: String,
			default: ''
		},
		icon: {
			type: String,
			default: ''
		},
		itemEdit: {
			type: String,
			default: ''
		},
		sourceAction: {
			type: String,
			default: ''
		},
		targetAction: {
			type: String,
			default: ''
		}
	},
	computed: {
		target() {
			return this.connections.filter((c) => c.target === this.item.id && c.modelId === this.model.id)
		},
		source() {
			return this.connections.filter((c) => c.source === this.item.id && c.modelId === this.model.id)
		}
		// item() {
		// 	return this.$store.state[this.itemType] ? this.$store.state[this.itemType].all.find((d) => d.id === this.itemId) : {}
		// }
	},
	methods: {
		getChartDataSource(connectionData) {
			let chartData = {}

			let children = connectionData.map((c) => ({
				name: c.targetName
			}))

			let data = {
				name: this.item.name,
				children
			}

			chartData.label = {
				position: 'left',
				verticalAlign: 'middle',
				align: 'right',
				color: '#fd0084',
				fontSize: 14
			}

			chartData.leaves = {
				label: {
					position: 'right',
					verticalAlign: 'middle',
					align: 'left',
					color: 'black',
					fontSize: 14
				}
			}

			chartData.data = data
			chartData.orient = 'LR'
			return chartData
		},
		getGdprColor(id) {
			let x = this.$store.state.fields.all.find((f) => f.id == id)
			let status = x ? x.gdprStatus : 'not-found'
			switch (status) {
				case 'sensitive':
					return '#f44336'
				case 'personal':
					return '#ffc107'
				case 'not-found':
					return '#00'
				default:
					return '#4caf50'
			}
		},
		getChartDataTarget(connectionData) {
			let chartData = {}
			let children = connectionData.map((c) => ({
				name: c.sourceName
			}))

			let data = {
				name: this.item.name,
				children
			}

			chartData.label = {
				position: 'right',
				verticalAlign: 'middle',
				color: '#fd0084',
				align: 'left',
				fontSize: 14
			}

			chartData.leaves = {
				label: {
					position: 'left',
					verticalAlign: 'middle',
					align: 'right',
					color: 'black',
					fontSize: 14
				}
			}

			chartData.data = data
			chartData.orient = 'RL'
			return chartData
		}
	}
}
</script>

<style></style>
