<template>
	<div :style="chartSize">
		<chart v-if="chartData" :style="chartSize" :options="modelData" autoresize />
	</div>
</template>

<script>
import helpers from '@/helpers/utils'
export default {
	name: 'EntityChart',
	props: {
		chartData: {
			type: Object,
			default: function() {
				return {}
			}
		},
		mapTitle: {
			type: String,
			default: '',
			required: false
		}
	},
	data() {
		return {}
	},
	computed: {
		chartSize() {
			return {
				width: '100%',
				height: `${this.chartData.data.children.length * 20 + 100}px`
			}
		},
		modelData() {
			return {
				series: [
					{
						type: 'tree',
						data: this.chartData.data ? [this.chartData.data] : [],
						orient: this.chartData.orient,
						top: '10%',
						left: '30%',
						bottom: '10%',
						right: '30%',
						roam: false, // true,
						symbolSize: 9,

						label: this.chartData.label,

						leaves: this.chartData.leaves,

						expandAndCollapse: true,
						animationDuration: 550,
						animationDurationUpdate: 750
					}
				],
				title: {
					text: `${this.chartData.data.name} ${this.mapTitle}:`,
					x: 'center',
					textStyle: {
						fontSize: 12
					}
				},
				color: ['#fd0084']
			}
		}
	},
	mounted() {
		this.chartData.data
	},
	methods: {
		getModelData() {
			let components = [
				{ name: 'creators', depth: 0, conf: 'configure-creator' },
				{ name: 'fields', depth: 1, conf: 'configure-field' },
				{ name: 'entities', depth: 2, conf: 'configure-entity' },
				{ name: 'channels', depth: 3, conf: 'configure-channel' }
			]
			let x = []

			components.forEach((comp) => {
				if (!this.model[comp.name]) {
					return
				}

				let m = Object.values(this.model[comp.name]).map((c) => ({
					name: c.id,
					_name: helpers.shortName(c.name),
					conf: comp.conf,
					value: c.name,
					type: comp.name,
					depth: comp.depth
				}))
				x.push(...m)
			})

			return x
		},
		getModelConnections() {
			return Object.values(this.connections)
		}
	}
}
</script>
