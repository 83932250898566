<template>
	<div>
		<top-bar title="Configure Model" :action="topAction" />
		<div class="flex flex-wrap mb-4 -mx-2">
			<div class="md:w-1/4 p-2 w-full">
				<div class="sticky top-20 mb-8">
					<ul class=" mt-4 overflow-x-hidden">
						<li v-for="(part, key) in parts.slice(0, 1)" :key="key + part" class="mb-2 mr-6">
							<a
								class="cursor-pointer mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150"
								@click="scrollMeTo(part.name)"
							>
								<span class="truncate">{{ part.name | capitalize }}</span>
								<span
									class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-gray-600 bg-gray-100 group-hover:bg-gray-200 group-focus:bg-gray-200 transition ease-in-out duration-150"
								>
									{{ itemsLength(part.name) }}
								</span>
							</a>
						</li>
						<li v-for="(part, key) in parts.slice(1, parts.length)" :key="key + 'ff'" class="mb-2 mr-6">
							<a
								class="cursor-pointer mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150"
								@click="scrollMeTo(part.name)"
							>
								<span class="truncate">{{ part.name | capitalize }}</span>
								<span
									class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-gray-600 bg-gray-100 group-hover:bg-gray-200 group-focus:bg-gray-200 transition ease-in-out duration-150"
								>
									{{ itemsLength(part.name) }}
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="md:w-3/4 p-2">
				<div v-for="(part, key) in parts.slice(0, 1)" :ref="part.name" :key="key + part.name">
					<h2 class="p-3">
						{{ part.name | capitalize }}
					</h2>
					<div class="bg-white shadow overflow-hidden sm:rounded-md">
						<ul>
							<li>
								<router-link
									:to="{ name: part.conf }"
									class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
								>
									<div class="px-4 py-4 sm:px-6">
										<div class="flex items-center justify-between">
											<div class="text-sm leading-5 font-medium text-indigo-600 truncate">
												Edit Fields
											</div>
										</div>
										<div class="mt-2 sm:flex sm:justify-between">
											<div class="sm:flex">
												<div class="mr-6 flex items-center text-sm leading-5 text-gray-500">
													Edit Fields for selection in this model
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</li>
						</ul>
					</div>
				</div>
				<div v-for="(part, key) in parts.slice(1, parts.length)" :ref="part.name" :key="key">
					<h2 class="p-3">
						{{ part.name | capitalize }}
					</h2>
					<div class="bg-white shadow overflow-hidden sm:rounded-md">
						<ul>
							<li v-for="(item, i) in getItems(part.name)" :key="item.id + 'n'" :class="i > 0 ? 'border-t border-gray-200' : ''">
								<router-link
									:to="{ name: part.conf, params: { configId: item.id, configName: cleanName(item.name) } }"
									class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
								>
									<div class="px-4 py-4 sm:px-6">
										<div class="flex items-center justify-between">
											<div class="text-sm leading-5 font-medium text-indigo-600 truncate">
												{{ item.name }}
											</div>
											<div v-if="item.selected" class="ml-2 flex-shrink-0 flex">
												<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
													Included
												</span>
											</div>
										</div>
										<div class="mt-2 sm:flex sm:justify-between">
											<div class="sm:flex">
												<div class="mr-6 flex items-center text-sm leading-5 text-gray-500">
													{{ item.description }}
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '@/helpers/utils'
import topBar from '@/components/shared/topBar'
export default {
	name: 'ModelConfig',
	components: {
		topBar
	},
	data() {
		return {
			parts: [
				{ name: 'fields', conf: 'configure-fields', icon: 'edit' },
				{ name: 'entities', conf: 'configure-entity', icon: 'cube' },
				{ name: 'creators', conf: 'configure-creator', icon: 'pencil-ruler' },
				{ name: 'channels', conf: 'configure-channel', icon: 'dot-circle' }
				// { name: 'rules', conf: 'configure-rule' }
			]
		}
	},
	computed: {
		...mapState({
			creators: (state) => state.creators.all,
			entities: (state) => state.entities.all,
			channels: (state) => state.channels.all,
			fields: (state) => state.fields.all
			// rules: (state) => state.rules.all
		}),
		...mapState('models', {
			model: (state) => state.current
		}),
		topAction() {
			return { name: 'Back to Overview', to: { name: 'model', params: { id: `${this.model.id}`, name: `${this.model._name}` } } }
		}
	},
	mounted() {
		this.$analytics.logEvent('configure_model', { name: this.model.name })
	},
	methods: {
		cleanName: (string) => helpers.shortName(string),
		modelElements(name) {
			return this.model[name] ? Object.values(this.model[name]) : []
		},
		itemsLength(name) {
			return this.getItems(name).filter((f) => f.selected).length
		},
		getItems(name) {
			return this.$store.state[name].all
				.map((i) => ({
					name: i.name,
					sort: helpers.shortName(i.name),
					description: i.description,
					gdprStatus: i.gdprStatus,
					id: i.id,
					selected: this.modelElements(name).some((e) => e.id === i.id)
				}))
				.sort((a, b) => (a.selected < b.selected ? 1 : a.selected === b.selected ? (a.sort > b.sort ? 1 : -1) : -1))
		},
		scrollMeTo(refName) {
			var element = this.$refs[refName]
			var top = element[0].offsetTop
			window.scrollTo(0, top)
		}
	}
}
</script>
